export enum CacheBackend {
  S3 = "S3",
  GCS = "GCS",
}

export enum PAGES_CACHE {
  VIDEOPAGE = "Videopage",
}

export enum VIEW_FROM {
  DIRECT = "DIRECT",
  PLAYLIST = "PLAYLIST",
  SEARCH_BOOK = "SEARCH_BOOK",
  SEARCH_DEFAULT = "SEARCH_DEFAULT",
  SEARCH_PLAYLIST = "SEARCH_PLAYLIST",
  SEARCH_LIVE_CLASS = "SEARCH_LIVE_CLASS",
  SEARCH_VIDEO = "SEARCH_VIDEO",
  SIMILAR = "SIMILAR",
  SIMILAR_PLAYLIST = "SIMILAR_PLAYLIST",
  NEXT_BTN = "NEXT_BTN",
  PREV_BTN = "PREVIOUS_BTN",
  SRP = "SRP",
  GENERIC_SUGGESTIONS = "GENERIC_SUGGESTIONS",
  AUTOPLAY = "AUTOPLAY",
  LANG_SWITCH = "LANGUAGE_SWITCH",
  QUIZ = "QUIZ",
  BLOCKED = "BLOCKED",
}

export enum PAGE {
  SEMINAR = "SEMINAR",
  VIDEO_PAGE = "WEB_VIDEO",
  AD_REWARD_MODAL = "WEB_AD_REWARD",
  PRO = "WEB_PRO",
  COURSE = "COURSE",
  PAYMENT = "PAYMENT",
  CHECKOUT = "CHECKOUT",
  MY_COURSE = "MY_COURSE",
  VIDEO_PLAYER = "VIDEO_PLAYER",
}

export enum LOGIN_SCREENS {
  MOBILE = "Login",
  VERIFY_PIN = "Verify PIN",
  VERIFY_OTP = "Verify OTP" 
}